



































import IconOpenGroup from '@/components/UI/icons/IconOpenGroup.vue'
import { publicGroupsStore, uiStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconOpenGroup,
  },
})
export default class SneakpeekControls extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly chatId!: string

  get isJoining (): boolean {
    return publicGroupsStore.state.isJoining
  }

  async join (): Promise<void> {
    await publicGroupsStore.actions.join(this.chatId)
  }

  handleListButtonClick (): void {
    uiStore.actions.showModal({
      instance: 'GroupJoin',
    })
  }
}
