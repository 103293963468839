


































import { groupsStore } from '@/store'
import { ChatEventBus, Events as ChatEvents } from '@/components/Chat/ChatEventBus'
import { Message } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconThumbTack: () => import('@/components/UI/icons/IconThumbTack.vue'),
    IconTimes: () => import('@/components/UI/icons/IconTimes.vue'),
  },
})
export default class PinnedMessage extends Vue {
  // TODO: fix this after refactor `modules/chats`
  @Prop({
    type: Object,
    default: false,
  }) readonly message!: Message

  unpinMessage (): void {
    const payload = {
      groupId: this.message.chat,
      messageId: this.message.messageId,
      unpin: true,
    }
    groupsStore.actions.pinMessage(payload)
  }

  goToMessage (): void {
    ChatEventBus.$emit(ChatEvents.TOGGLE_IMPORTANT_MESSAGES_VIEWER, false)
    ChatEventBus.$emit(ChatEvents.GO_TO_MESSAGE, {
      chatId: this.message.chat,
      messageId: this.message.messageId,
    })
  }
}
