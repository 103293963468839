//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ChatEventBus, Events } from './ChatEventBus'
import { isWebRTCSupported } from '@/utils'
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/actionTypes'
import ChatForm from './ChatForm'
import FileDropMixin from '@/components/MiddleColumn/Mixins/FileDrop'
import ImportantMessagesList from './Instance/Hooks/ImportantHook'
import Messages from './Instance/Hooks/MainHook'
import NotifyCallBar from '@/components/Calls/NotifyCallBar'
import PinnedMessage from './PinnedMessage'
import SneakpeekControls from './SneakpeekControls'
import TaskDropMixin from '@/components/MiddleColumn/Mixins/TaskDrop'
import { callsStore, activeCallStore, publicGroupsStore } from '@/store'

export default {
  name: 'Chat',
  components: {
    'chat-form': ChatForm,
    messages: Messages,
    'important-messages-list': ImportantMessagesList,
    'pinned-message': PinnedMessage,
    'notify-call-bar': NotifyCallBar,

    SneakpeekControls,
  },
  mixins: [FileDropMixin, TaskDropMixin],
  data () {
    return {
      importantViewerActive: false,
    }
  },
  watch: {
    currentChat (newV, prevV) {
      if (newV !== prevV) {
        ChatEventBus.$emit(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, false)

        const { dispatch } = this.$store
        dispatch(actionTypes.CHAT_OPENED, { chatId: newV })
      }
    },
  },
  created () {
    this.$options.isWebRTCSupported = isWebRTCSupported()
  },
  computed: {
    ...mapGetters([
      'call',
      'chat',
      'currentChat',
      'entity',
    ]),
    hasPinned () {
      return !!this.pinnedMessage
    },
    chatEntity () {
      return this.entity(this.currentChat)
    },
    chatObj () {
      return this.chat(this.currentChat)
    },
    pinnedMessage () {
      return this.chatEntity?.pinnedMessage
    },
    canSendMessage () {
      if (this.chatObj?.chatType === 'direct' || this.chatObj?.chatType === 'thread') {
        return this.chatObj?.canSendMessage || this.chatEntity?.canSendMessage
      }
      return this.chatEntity?.canSendMessage
    },
    readonlyCaption () {
      return this.chatEntity?.cantSendMessageReason ?? this.$t('chattape.readOnly')
    },
    canCall () {
      if (
        !this.$options.isWebRTCSupported ||
        !callsStore.getters.isCallingEnabled()
      ) return false

      return this.chatEntity && this.chatEntity.canCall
    },
    calling () {
      if (!this.canCall) return

      return (
        activeCallStore.state.activeCall?.jid === this.currentChat ||
        callsStore.getters.call(this.currentChat)
      )
    },
    isForSneakpeek () {
      return publicGroupsStore.state.sneakpeekId === this.currentChat
    },
  },
  mounted () {
    if (this.currentChat) {
      const { dispatch } = this.$store
      dispatch(actionTypes.CHAT_OPENED, { chatId: this.currentChat })
    }

    this.handleImportantViewer = value => { this.importantViewerActive = !!value }
    ChatEventBus.$on(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
  },
  beforeDestroy () {
    ChatEventBus.$off(Events.TOGGLE_IMPORTANT_MESSAGES_VIEWER, this.handleImportantViewer)
  },
}
